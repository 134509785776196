/* the slides */
.slick-slide {
    margin: 0 10px;
    overflow: hidden;
    min-width: 0px;
}

/* the parent */
.slick-list {
    margin: 0 -10px;
    width: inherit
}

.slick-track {
    overflow: hidden;
    display: flex;
    width: 1440px
}