.card {
  box-shadow: 0 1.5em 2.5em -0.5em rgba(#000000, 0.1);
  transition: transform 0.45s ease;
  background: 0.45s ease;
}

.headTop {
  color: black;
  transition: color 0.45s ease, border 0.45s ease;
}

.headText {
  color: black;
  margin: 0 0 1.777777778em;
  transition: color 0.45s ease;
}

.card:hover {
  color: #ffffff;
  background: #c4a484;
  transform: scale(1.02);
}
.card.headTop:hover {
  color: #ffffff;
}

.card.headText:hover {
  color: #ffffff;
}

@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.125em);
  }
}
