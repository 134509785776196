.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
  text-align: center;
}

.ag-filter-apply-panel button {
  margin-right: 20px;
  /* Adds a gap between buttons */
}