.bg-light {
  background: #f4f6f8;
}

.h-100vh {
  height: 100vh;
}

.w-100 {
  width: 100% !important;
}

.MuiDropzoneArea-root {
  width: 100%;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 150px;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
}

.login-bg {
  background-image: url("../images/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.p-o {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mr-2 {
  margin-right: 8px;
}

.my-1 {
  margin: 8px 0;
}

.mx-auto {
  margin: 0 auto !important;
}

.p-0 {
  padding: 0 !important;
}

.bg-light {
  background: #f4f6f9;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.MuiAppBar-colorPrimary {
  color: #376fd0;
  background-color: #fff;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-transform: uppercase;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.w-50 {
  width: 50%;
}

.nowrap {
  flex-wrap: nowrap !important;
}

@page {
  size: 21cm 29.7cm;
  margin: 30mm 45mm 30mm 45mm;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

@media print {
  @page {
    size: portrait;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(197, 197, 197);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 150, 150);
}

.dg_dash_entry1 {
  padding: 24px 12px;
  background-color: #5355AD;
  border-radius: 30px;
  box-sizing: border-box;
}

.dg_dash_cc {
  padding: 20px;
  background-color: #fff;
  border-radius: 30px;
  margin: 0px;
  box-sizing: border-box;
  box-shadow: 9px 6px 4px 0px #292B7F;
}

.dg_dash_cc_tagline {
  color: #E92784;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
}

.dg_dash_cc_title {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0;
}

.dg_dash_entry1_para {
  margin: 4px 0 0 0;
  padding: 0;
  color: #6C6D92;
}

.dg_dash_entry1_count {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dg_dash_cc_count_2 {
  background-color: #63B034;
  padding: 4px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 2rem;
  margin: 0;
  text-align: center;
  margin-bottom: 2px;
}

.dg_dash_cc_count_3 {
  background-color: #F95858;
  padding: 4px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 2rem;
  margin: 0;
  text-align: center;
}

.dg_dash_entry1_count_main,
.dg_dash_entry1_count_sub {
  flex: 1 0;
}

.dg_dash_entry1_count_main h3 {
  font-size: 4rem;
  line-height: 4rem;
  margin: 0;
  padding: 0;
  color: #3E4095;
}

.dg_dash_entry1_count_main span {
  font-weight: 700;
  color: #000;
}

.dg_dash_entry2 {
  padding: 24px 12px;
  background-color: #3E95D4;
  border-radius: 30px;
  box-sizing: border-box;
  margin-top: 20px !important;
}

.dg_dash_am {
  padding: 20px;
  background-color: #fff;
  border-radius: 30px;
  margin: 0px;
  box-sizing: border-box;
}

.dg_dash_am_title {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0;
}

.dg_dash_am_gauge_count {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-top: 12px;
  padding-bottom: 12px;
}

.dg_dash_am_gauge_count_no {
  font-size: 3rem;
  line-height: 3rem;
  color: #F7A13C;
  font-weight: 700;
  min-width: 140px;
}

.dg_dash_am_gauge_count_title {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #606073;
  max-width: 200px;
  text-align: right;
}

.dg_dash_am_gauge_count:last-child {
  border: 0;
}


@media screen and (max-width: 764px) {
  .dg_sidebar {
    display: none;
  }

  .dg_content {
    width: 100%;
    left: 0;
    padding: 24px;
  }

  .dg_dash_cc,
  .dg_dash_am {
    margin-top: 20px;
  }

  .dg_dash_entry1,
  .dg_dash_entry2 {
    margin-top: 0px !important;
    margin-left: -10px !important;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .dg_dash_entry2 {
    margin-top: 20px !important;
  }
}

.AZeKs {
  box-shadow: 0px 2px 4px lightgrey;
}

.ag-header-cell-label {
  justify-content: center !important;

}

.ag-header-cell-text {
  font-weight: bold;
  font-size: larger;
}

.ag-theme-material .custom-inline-editing-cell.ag-cell-inline-editing {
  padding: var(--ag-grid-size);
  height: calc(var(--ag-row-height) + var(--ag-grid-size) * 25) !important;
  overflow: scroll;
}