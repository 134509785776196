.detail_row_gauge {
    text-align: end;
}

.datePicker {
    line-height: 4;
    margin-right: 1rem;
    width: 100%;
    .react-date-picker__wrapper{
       border: thin solid #BBBABA;
       border-radius: 5px;
    }
}