@media print {
    html {
        zoom: 67%;
    }

    footer {
        page-break-after: always !important;
    }

    .Jai {
        overflow: visible !important;
    }

    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        display: block;
        page-break-before: auto;
    }
}

.Main {
    font-family: 'Montserrat';
    font-weight: bold;
}

h3,
header {
    text-align: center;
    font-weight: bold;
}

h4 {
    text-align: center;
    padding-bottom: 15px;
    font-size: x-small;
}

h6 {
    text-align: center;
    font-weight: bold;
}

.dateText {
    text-align: center;
    font-size: 0.6em;
}

.customChart {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    height: 250px;
    width: 65%;
    margin: 0 auto;
}

.customText {
    font-size: 1em;
}

.customTypo {
    background-color: rgb(218, 218, 218);
    border-radius: 5px;
}

.customCards {
    width: 100%;
}

.additional {
    text-align: center;
    padding-bottom: 20px;
}



footer {
    text-align: center;
}

.numberCol {
    text-align: right;
}

.foot {
    padding: 20px;
}